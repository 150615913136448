import { b2x } from '@b2x/react/src';
import React from 'react';

import { BoxA } from '../BoxA';
import { BoxB } from '../BoxB';
import { BoxC } from '../BoxC';
import { Container } from '../Container';
import { IconName, iconsNames } from '../Icon';
import { NutritionalTable } from '../NutritionalTable';
import { SliderCFromDirectory } from '../slider/SliderCFromDirectory';
import { Page } from './Page';

export const themeColors = [
  'primary',
  'secondary',
  'light-blue',
  'green',
  'brown',
  'red',
  'black',
  'white',
  'gray-100',
  'gray-200',
  'gray-300',
  'gray-400',
  'gray-900',
] as const;
export type ThemeColor = (typeof themeColors)[number];

export const TemplatePage = () => {
  const page = b2x.usePage({
    populate: {
      content: false,
    },
  });

  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRIORITY', orderingType: 'ASC' },
    { orderBy: 'PRIORITY', orderingType: 'DESC' },
    { orderBy: 'NAME', orderingType: 'ASC' },
    { orderBy: 'NAME', orderingType: 'DESC' },
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
    { orderBy: 'ID', orderingType: 'ASC' },
    { orderBy: 'ID', orderingType: 'DESC' },
  ]);

  const { getSku } = b2x.useProductsApi();

  // React.useEffect(() => {
  //   searchProducts(undefined, {
  //     pageSize: 3,
  //     populate: {
  //       items: {
  //         alternativeImages: true,
  //         assembledComponents: true,
  //         associatedProducts: true,
  //         attributeGroups: true,
  //         attributes: true,
  //         brand: true,
  //         breadcrumbs: true,
  //         bundleProducts: true,
  //         content: true,
  //         offers: true,
  //         pages: true,
  //         priceRange: true,
  //         reviews: true,
  //         skus: {
  //           alternativeImages: true,
  //           attributes: true,
  //           closestExpiringDate: true,
  //           measurement: true,
  //           price: true,
  //           product: true,
  //         },
  //         summaryReviews: true,
  //         variantProducts: true,
  //       },
  //     },
  //     products: ['386439'],
  //   }).then((response) => {
  //     const products = response.data.items;
  //     if (products) {
  //       b2x.filler
  //         .fillProducts(products, {
  //           alternativeImages: true,
  //           // assembledComponents: true,
  //           // associatedProducts: true,
  //           // attributeGroups: true,
  //           attributes: true,
  //           brand: true,
  //           breadcrumbs: true,
  //           // bundleProducts: true,
  //           // content: true,
  //           // offers: true,
  //           // pages: true,
  //           priceRange: true,
  //           reviews: true,
  //           skus: {
  //             alternativeImages: true,
  //             attributes: true,
  //             // closestExpiringDate: true,
  //             measurement: true,
  //             price: true,
  //             product: true,
  //           },
  //           summaryReviews: true,
  //           // variantProducts: true,
  //         })
  //         .then((filledProducts) => {
  //           // console.log(filledProducts);
  //         });
  //     }
  //   });
  // }, [searchProducts]);

  // React.useEffect(() => {
  //   getProduct('386439', {
  //     populate: {
  //       alternativeImages: true,
  //       assembledComponents: true,
  //       associatedProducts: true,
  //       attributeGroups: true,
  //       attributes: true,
  //       brand: true,
  //       breadcrumbs: true,
  //       bundleProducts: true,
  //       content: true,
  //       offers: true,
  //       pages: true,
  //       priceRange: true,
  //       reviews: true,
  //       skus: {
  //         alternativeImages: true,
  //         attributes: true,
  //         closestExpiringDate: true,
  //         measurement: true,
  //         price: true,
  //         product: true,
  //       },
  //       summaryReviews: true,
  //       variantProducts: true,
  //     },
  //   }).then((response) => {
  //     const product = response.data;
  //     b2x.filler
  //       .fillProduct(product, {
  //         alternativeImages: true,
  //         // assembledComponents: true,
  //         // associatedProducts: true,
  //         // attributeGroups: true,
  //         attributes: true,
  //         brand: true,
  //         breadcrumbs: true,
  //         // bundleProducts: true,
  //         // content: true,
  //         // offers: true,
  //         // pages: true,
  //         priceRange: true,
  //         reviews: true,
  //         skus: {
  //           alternativeImages: true,
  //           attributes: true,
  //           // closestExpiringDate: true,
  //           measurement: true,
  //           price: true,
  //           product: true,
  //         },
  //         summaryReviews: true,
  //         // variantProducts: true,
  //       })
  //       .then((filledProduct) => {
  //         // console.log(filledProducts);
  //       });
  //   });
  // }, [getProduct]);

  React.useEffect(() => {
    getSku('763039', {
      populate: {
        alternativeImages: true,
        attributes: true,
        closestExpiringDate: true,
        measurement: true,
        price: true,
        product: true,
      },
    }).then((response) => {
      const sku = response.data;
      b2x.filler
        .fillSku(sku, {
          alternativeImages: true,
          attributes: true,
          // closestExpiringDate: true,
          measurement: true,
          price: true,
          product: true,
        })
        .then((filledSku) => {
          // console.log(filledSku);
        });
    });
  }, [getSku]);

  return (
    <Page thingsToLoadBeforePageReady={[page]}>
      {/* <TemplateSection title="png vs webp">
        <b2x.Container>
          {[...Array(5)].map((col, index) => (
            <b2x.Row key={col}>
              <b2x.Col size={6}>
                <div>png</div>
                <img src={`https://www.gstatic.com/webp/gallery/${index + 1}.jpg`} style={{ maxWidth: '100%' }} />
                <br />
                <br />
              </b2x.Col>
              <b2x.Col size={6}>
                <div>webp</div>
                <img src={`https://www.gstatic.com/webp/gallery/${index + 1}.webp`} style={{ maxWidth: '100%' }} />
                <br />
                <br />
              </b2x.Col>
            </b2x.Row>
          ))}
        </b2x.Container>
      </TemplateSection> */}
      <TemplateSection title="Grid System">
        <b2x.template.GridSystem />
      </TemplateSection>
      <TemplateSection title="Typography">
        <b2x.template.Typography
          variants={[
            { className: 'display-1', name: 'Display 1' },
            // { className: 'display-2', name: 'Display 2' },
            // { className: 'display-3', name: 'Display 3' },
            // { className: 'display-4', name: 'Display 4' },
            // { className: 'display-5', name: 'Display 5' },
            // { className: 'display-6', name: 'Display 6' },
            { className: 'h1', name: 'Heading 1' },
            { className: 'h2', name: 'Heading 2' },
            { className: 'h3', name: 'Heading 3' },
            { className: 'h4', name: 'Heading 4' },
            { className: 'h5', name: 'Heading 5' },
            { className: 'h6', name: 'Heading 6' },
            { className: 'fw-bold', name: 'Bold text' },
            { className: 'fw-normal', name: 'Normal text' },
            { className: 'fw-semi-bold', name: 'Semibold text' },
            { className: 'small ', name: 'Small text' },
            { className: 'extra-small', name: 'Extra small text' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Icons">
        <b2x.template.Icons names={iconsNames} />
      </TemplateSection>

      <TemplateSection title="Spacing">
        <b2x.template.Spacing />
      </TemplateSection>

      <TemplateSection title="Colors">
        <b2x.template.Colors
          colors={[
            { background: 'primary', text: 'black' },
            { background: 'secondary', text: 'white' },
          ]}
        />
        <b2x.template.Colors
          colors={[
            { background: 'light-blue', text: 'white' },
            { background: 'green', text: 'white' },
            { background: 'brown', text: 'white' },
            { background: 'red', text: 'white' },
          ]}
        />
        <b2x.template.Colors
          colors={[
            { background: 'black', text: 'white' },
            { background: 'white', text: 'black' },
          ]}
        />
        <b2x.template.Colors
          colors={[
            { background: 'gray-100', text: 'black' },
            { background: 'gray-200', text: 'black' },
            { background: 'gray-300', text: 'white' },
            { background: 'gray-400', text: 'white' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Form">
        <Container>
          <b2x.ExampleForm />
        </Container>
      </TemplateSection>

      <TemplateSection title="Buttons">
        <b2x.template.Buttons
          backgrounds={['white', 'black']}
          iconsOrLabels={['onlyLabel', 'onlyIcon', 'IconLeftAndLabel', 'IconRightAndLabel']}
          sizes={[undefined, 'small']}
          variants={['primary', 'secondary', 'gray-400', 'gray-200', 'white', 'transparent', 'link']}
        />
      </TemplateSection>

      <TemplateSection title="Dropdowns">
        <Container>
          <div className="row">
            <b2x.template.Dropdowns
              hideAlignments
              hideAutoClose
              hideComponent
              hideDarkVariant
              hideDirections
              hideOffsets
              sizes={[undefined]}
              variants={['primary', 'secondary']}
            />
          </div>
        </Container>
      </TemplateSection>
      <TemplateSection title="Badges">
        <b2x.template.Badges<ThemeColor, IconName>
          backgroundOpacities={[undefined, 10, 25]}
          backgroundVariants={[...themeColors]}
          badges={[
            {
              backgroundOpacity: 25,
              backgroundVariant: 'red',
              children: <strong>Fino al 28% di sconto</strong>,
              textVariant: 'red',
            },
            {
              backgroundOpacity: 10,
              backgroundVariant: 'secondary',
              children: <strong>Spedizioni gratuite</strong>,
              textVariant: 'secondary',
            },
            {
              backgroundVariant: 'white',
              children: <strong>Spedizioni gratuite</strong>,
              textVariant: 'secondary',
            },
          ]}
          icons={[undefined, 'cart']}
          textVariants={[undefined, 'black', 'light-blue', 'red']}
        />
      </TemplateSection>
      <TemplateSection title="Boxes">
        <b2x.Container>
          <b2x.Row>
            <b2x.Col size={{ lg: 3, xs: 12 }}>
              <h3>Box A</h3>
              <BoxA
                background={{ color: 'pink' }}
                cta={{ label: 'Scopri tutta la pasta', to: { code: 'SITEMAP' }, variant: 'transparent' }}
                slider={{
                  slides: [
                    { contentSectionId: 1, image: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' } },
                  ],
                }}
                title="Pasta"
              />
            </b2x.Col>
            <b2x.Col size={{ lg: 3, xs: 12 }}>
              <h3>Box A (multiple images)</h3>
              <BoxA
                background={{ color: 'pink' }}
                cta={{ label: 'Scopri tutta la pasta', to: { code: 'SITEMAP' }, variant: 'transparent' }}
                slider={{
                  slides: [
                    { contentSectionId: 1, image: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' } },
                    { contentSectionId: 1, image: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' } },
                  ],
                }}
                title="Pasta"
              />
            </b2x.Col>
            <b2x.Col size={{ lg: 3, xs: 12 }}>
              <h3>Box B</h3>
              <BoxB
                background={{ url: 'https://www.b2x.it/rest/images/2024/04/24/1507177.png' }}
                cta={{ label: 'Scopri i prodotti', to: { code: 'SITEMAP' }, variant: 'white' }}
                image={{
                  offsetX: 30,
                  offsetY: 30,
                  src: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' },
                }}
                percentage={30}
                title="Promo primavera"
              />
            </b2x.Col>
            <b2x.Col size={{ lg: 3, xs: 12 }}>
              <h3>Box D</h3>
              {/* <BoxD
                copy={{ content: 'tra la box pasta semola e la box pasta integrale', title: 'Scegli la box' }}
                image={{ url: 'https://b2x2.b-cdn.net/images/2024/10/17/1524537.png?imageFormat=@2x' }}
              /> */}
            </b2x.Col>
            <b2x.Col size={{ lg: 3, xs: 12 }}>
              <h3>Box E</h3>
              {/* <BoxE
                copy={{
                  color: '#FFFFFF',
                  content: 'Risparmia fino a 23,10€',
                  labelWithIcon: { icon: 'wishlist', label: 'Spedizioni gratuite' },
                  title: 'La box con la pasta integrale',
                }}
                image={{ url: 'https://b2x2.b-cdn.net/images/2024/10/17/1524546.png?imageFormat=@2x' }}
              /> */}
            </b2x.Col>
          </b2x.Row>
          <b2x.Row>
            <b2x.Col size={{ lg: 4, xs: 12 }}>
              <h3>Box C</h3>
              <BoxC
                slides={[
                  {
                    background: {
                      color: 'blueviolet',
                      image: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' },
                    },
                    content:
                      'L’esclusiva ricetta De Cecco si caratterizza per un impiego di ben 80% di patate fresche, che rende gli gnocchi ancora più morbidi',
                    contentSectionId: 1,
                    cta: { label: 'Scopri tutti gli gnocchi', to: { code: 'SITEMAP' }, variant: 'white' },
                  },
                  {
                    background: {
                      color: 'green',
                      image: { url: 'https://www.b2x.it/rest/images/2024/04/24/1507232.png' },
                    },
                    content:
                      'L’esclusiva ricetta De Cecco si caratterizza per un impiego di ben 80% di patate fresche, che rende gli gnocchi ancora più morbidi',
                    contentSectionId: 1,
                    cta: { label: 'Scopri tutti gli spaghetti', to: { code: 'SITEMAP' }, variant: 'white' },
                  },
                ]}
              />
            </b2x.Col>
          </b2x.Row>
        </b2x.Container>
      </TemplateSection>

      <TemplateSection title="Sliders">
        <b2x.template.Sliders />
        <b2x.Container>
          <b2x.Row>
            <b2x.Col size={{ lg: 12, xs: 12 }}>
              <h3>Slider temporizzato da Directory</h3>
              <SliderCFromDirectory directoryCode="SLIDER_HOME_TOP" />
            </b2x.Col>
          </b2x.Row>
        </b2x.Container>
      </TemplateSection>

      <TemplateSection title="Table">
        <Container>
          <b2x.Table border="light" className="border" colNumber={10} responsive xAlignment="center">
            <b2x.TableHead variant="light">
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((th) => (
                  <th key={th}>th{th}</th>
                ))}
              </tr>
            </b2x.TableHead>
            <b2x.TableBody>
              {[1, 2, 3, 4, 5].map((tr) => (
                <tr key={tr}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((td) => (
                    <td key={td}>
                      tr{tr} td{td}
                    </td>
                  ))}
                </tr>
              ))}
            </b2x.TableBody>
          </b2x.Table>
        </Container>
      </TemplateSection>

      <NutritionalTable />

      <TemplateSection title="Tabs">
        <Container>
          <b2x.template.Tabs />
        </Container>
      </TemplateSection>

      <TemplateSection title="Accordion">
        <Container>
          <b2x.Accordion id="AccordionExample">
            <b2x.AccordionItem id="One" title="Title ONE">
              <b2x.LoremIpsum />
            </b2x.AccordionItem>
            <b2x.AccordionItem id="Two" title="Title Two">
              <b2x.LoremIpsum />
            </b2x.AccordionItem>
          </b2x.Accordion>
        </Container>
      </TemplateSection>

      <TemplateSection title="Modals">
        <Container>
          <b2x.template.Modals />
        </Container>
      </TemplateSection>

      <TemplateSection title="Offcanvas">
        <Container>
          <b2x.template.Offcanvasses />
        </Container>
      </TemplateSection>

      <TemplateSection title="Pagination">
        <Container>
          <b2x.template.Paginations />
        </Container>
      </TemplateSection>

      <TemplateSection title="Product Tiles">
        <div className="container-fluid">
          <b2x.Listing name="Template page - Product Tiles" products={page?.products}>
            {page?.products && (
              <b2x.ProductsTiles
                enableExtraInfo
                products={page.products.map((product) => ({ product: product }))}
                productsPerRow={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2 }}
              />
            )}
          </b2x.Listing>
        </div>
      </TemplateSection>

      <TemplateSection title="Ordinamento prodotti">
        <Container>
          <b2x.Dropdown label="Ordina per">
            {sortingOptions.map((sortingOption) => (
              <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
                {sortingOption.label}
              </b2x.DropdownItem>
            ))}
          </b2x.Dropdown>
        </Container>
      </TemplateSection>
    </Page>
  );
};

interface TemplateSectionProps {
  children?: React.ReactNode;
  title?: string;
}

const TemplateSection = ({ children, title }: TemplateSectionProps) => {
  return (
    <section className="my-5">
      {title && (
        <Container>
          <div className="row">
            <div className="col-12">
              <h1 className="mb-4">{title}</h1>
            </div>
          </div>
        </Container>
      )}
      {children}
    </section>
  );
};
